/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Poznaj naszą firmę geodezyjną w Olsztynie | Doświadczenie i profesjonalizm</title>
    <meta
      name="description"
      content="Dowiedz się więcej o naszej firmie geodezyjnej. Od lat świadczymy wysokiej jakości usługi geodezyjne na terenie województwa warmińsko - mazurskiego jak i w całej Polsce, w tym obsługę lotnisk i lądowisk."
    />
    <link rel="canonical" href="https://geo-partner.pl/o-firmie/" />
  </>
);

const Main = styled.main`
  /* font-family: ${({ theme }) => theme.font.family.sans}; */
`;

const Slider = styled.div`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Team = styled.ul`
  list-style: none;
  margin: 5rem 0 0;
  padding: 0;

  figure {
    background: #9a98b9;
    border-radius: 100%;
    left: 0;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    margin: 0;
  }

  li {
    padding: 0 0 20px 130px;
    position: relative;
  }

  h3 {
    margin: 0 0 5px;
  }
`;

const IndexPage = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_5)}
        alt="Geodezja Olsztyn"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Geo Partner - firma geodezyjna</h1>

        <p>
          Firma na rynku usług geodezyjnych działa od 1998 roku. Ponad 25 lat doświadczenia gwarantuje wysoką jakość naszych usług.
        </p>

        <p>
          Od 2005 roku głównym profilem naszej działalności jest <strong>geodezyjna obsługa budowy i&nbsp;modernizacji dróg oraz linii kolejowych</strong>. W&nbsp;dalszym ciągu wykonujemy zlecenia także dla klientów indywidualnych.
        </p>

        <p style={{ marginBottom: '9rem' }}>
          Z wyrazami szacunku dla swoich stałych i nowych klientów zapraszamy do skorzystania z <Link to="/oferta/">naszych usług</Link>.
        </p>

        <h2 className="text-center">Nasz zespół</h2>

        <Team>
          <li>
            <figure>&nbsp;</figure>

            <h3>mgr inż. Radosław Ickiewicz</h3>

            <p>Absolwent ART w&nbsp;Olsztynie (1994). W&nbsp;latach od 1994 do 1999 pracował w&nbsp;Instytucie Geodezji na ART/UWM w&nbsp;Olsztynie. W&nbsp;Geo&nbsp;Partner Firma Geodezyjna od 1998 roku. Członek zarządu W-M Oddziału Stowarzyszenia Geodetów Polskich w Olsztynie. Posiada uprawnienia geodezyjne z&nbsp;zakresu 1, 2 i 4.</p>
          </li>

          <li>
            <figure></figure>

            <h3>mgr inż. Jerzy Podubiński</h3>

            <p>Absolwent ART w Olsztynie (1993). W&nbsp;latach od 1993 do 1998 pracownik Biura Urządzania Lasów i&nbsp;Geodezji Leśnej. W&nbsp;Geo Partner Firma Geodezyjna od 1998 roku. Aktywny członek W-M Oddziału Stowarzyszenia Geodetów Polskich w Olsztynie. Posiada uprawnienia geodezyjne z zakresu 1 i 2.</p>
          </li>
        </Team>

      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_5: file(relativePath: {regex: "/hero\/3.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    },
  }`;

export default IndexPage;
